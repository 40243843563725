@import '../../globals';

.app__header {
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    padding: 20px;
    display: flex;

    @include min600-max900 {
        flex-direction: column;
        align-items: center;
    }

    @include min700 {
        justify-content: space-between;
        align-items: center;
        padding: 100px;
    }
}

.app__header-logo {
    font-size: 25px;
    margin: 0;
    line-height: 1;
    text-decoration: none;
    display: block;

    @include min600-max900 {
        text-align: center;
        margin: 50px;
    }

    @include min900 {
        font-size: 30px;
    }
}

.app__navigation__link,
.app__navigation__social-link {
    &:hover,
    &:focus,
    &:hover svg,
    &:focus svg {
        text-decoration: underline;
        color: #ff7a00;
    }
}

a.app__navigation__link,
a.app__navigation__social-link {
    text-transform: uppercase;
    text-decoration: none;
    align-items: baseline;
    color: #fff;

    @include max600 {
        display: block;
        margin: 30px;
        font-size: 25px;
    }

    @include min600 {
        margin-left: 20px;
        color: #000;
    }
}
.app__navigation__social-link {
    width: 20px;
    display: inline-block;
    position: relative;
    top: -3px;
}

.app__navigation__link--active {
    font-weight: bold;
}

.app__header__toogle-menu {
    display: none;
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    color: #000;
    padding: 0;
    box-shadow: none;

    @include max600 {
        display: block;
    }

    &:hover {
        background: none;
    }
}

.menu-label {
    display: block;
    font-size: 12px;
}

@include max600 {
    .app__navigation {
        visibility: visible;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(26, 33, 45, 0.9);
        z-index: 9999;
        padding: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;

        .show-menu & {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.menu-label {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;

    .hide-menu & {
        opacity: 0;
    }
}

.menu-icon {
    z-index: 99999;
    width: 30px;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    margin: 0 auto;
    cursor: pointer;
}

.menu-line {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
        top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
        top: 10px;
    }

    &:nth-child(4) {
        top: 20px;
    }
}

.hide-menu .menu-line {
    background: #fff;

    &:nth-child(1) {
        top: 12px;
        width: 0%;
        left: 50%;
    }

    &:nth-child(2) {
        transform: rotate(45deg);
    }

    &:nth-child(3) {
        transform: rotate(-45deg);
    }

    &:nth-child(4) {
        top: 12px;
        width: 0%;
        left: 50%;
    }
}

@include max600 {
    .hide-menu {
        & + main,
        & .shx-logo {
            filter: blur(3px);
        }
    }
}
