@import '/globals';
@import '../node_modules/sanitize.css/sanitize.css';

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local('Raleway'), local('Raleway-Regular'),
        url('/fonts/raleway-v14-latin-regular.woff2') format('woff2'),
        url('/fonts/raleway-v14-latin-regular.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: local('Raleway Bold'), local('Raleway-Bold'),
        url('/fonts/raleway-v14-latin-700.woff2') format('woff2'),
        url('/fonts/raleway-v14-latin-700.woff') format('woff');
    font-display: swap;
}

body {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-style: normal;
}

// Sticky footer
html,
body {
    width: 100%;
    height: 100%;
}

main {
    flex-grow: 1;
    padding: 20px;
    min-height: 700px;

    h1 {
        text-align: center;
        margin-bottom: 40px;

        @include min600 {
            margin-bottom: 120px;
        }

        &::after {
            background: #000;
            height: 2px;
            width: 100px;
            content: '';
            display: block;
            margin: 0 auto;
        }
    }
}

header,
main,
footer {
    flex-shrink: 0;
}

input,
textarea {
    border: 1px solid #ccc;
    padding: 20px;
}

label {
    font-size: 18px;
    display: block;
}

button {
    padding: 13px 40px;
    text-transform: uppercase;
    background: #cf6800;
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 3px #00000036;
    margin: 0 auto;
    display: block;

    &:hover,
    &:focus {
        outline: none;
    }
}

a {
    &:link,
    &:visited {
        color: #000;
    }

    &:hover,
    &:focus {
        color: #ff7a00;
        outline: none;
    }
}

.app-content {
    max-width: 700px;
    margin: 0 auto;
}
