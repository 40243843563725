.shx-logo {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1;
    color: #000;

    &--bold {
        font-weight: 700;
        margin-left: -8px;
    }

    &--inverted {
        color: #fff;
    }
}
