@import './../../globals';

.app__footer {
    padding: 112px 0;
    text-align: center;
    color: #fff;
    background: $steinarhovland-blue;
    margin-top: 120px;

    .shx-logo {
        display: block;
        font-weight: 100;
    }

    &__policies-list {
        margin-top: 60px;

        &__link:link,
        &__link:visited {
            color: #fff;
            margin: 30px;
        }
    }

    &__social-icon:link,
    &__social-icon:visited {
        display: inline-block;
        margin: 20px;
        width: 30px;
        height: auto;
        color: #fff;
    }
}
