$steinarhovland-blue: #2b3342;
$steinarhovland-grey: #f7f7f7;

// 600
@mixin min600 {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin max600 {
    @media (max-width: 600px) {
        @content;
    }
}

// 700
@mixin min700 {
    @media (min-width: 700px) {
        @content;
    }
}

@mixin max700 {
    @media (max-width: 700px) {
        @content;
    }
}

// 800
@mixin min800 {
    @media (min-width: 800px) {
        @content;
    }
}

@mixin max800 {
    @media (max-width: 800px) {
        @content;
    }
}

// 900
@mixin min900 {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin max900 {
    @media (max-width: 900px) {
        @content;
    }
}

// Ranges
@mixin min600-max900 {
    @media (min-width: 600px) and (max-width: 900px) {
        @content;
    }
}
